import {base_url} from './baseurl';



export function createUserField(data){
    
    return fetch(base_url+'userfield',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}
export function updateUserField(data){
    
    return fetch(base_url+'userfields/updateUserField',{
        method:'PUT',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
  
}

export function getUserField(){
    
    return fetch(base_url+'userfields/getUserFields',{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
        },
    
    })
    .then(res=>res.json())
  
}
export function deleteUserfield(userId){
    
    return fetch(base_url+'userfield/'+userId,{
        method:'DELETE',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
        },
    
    })
    .then(res=>res.json())
  
}