import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ShipmentTable from '../../components/tables/ShipmentTable';
import DashboardContent from '../../layouts/DashboardLayout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getShippings } from '../../apis/ShippingApi';
import { getCountries } from '../../apis/Country';
import { getCarriers } from '../../apis/Carriers';
import { getServicesByCarrier } from '../../apis/Services';

const Shipment = () => {

    const [shipments,setShipments] = useState([]);
    const [shipmentsData,setShipmentsData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [page,setpage] = useState(1)
    const [countries,setCountries] = useState([]);
    const [carriers,setCarriers] = useState([]);
    const [services,setServices] = useState([]);



    const [searchCarrier,setSearchCarrier] = useState("");
    const [searchService,setSearchService] = useState("");
    const [searchRecieverLastName,setSearchRecieverLastName] = useState("");
    const [searchRecieverCountry,setSearchRecieverCountry] = useState("");
    const [searchRecieverZipcode,setSearchRecieverZipcode] = useState("");
    const [searchReference,setSearchReference] = useState("");
    const [searchTrackingID,setSearchTrackingID] = useState("");
    const [searchCarrierTrackingID,setSearchCarrierTrackingID] = useState("");
    const [searchFromDate,setSearchFromDate] = useState("");
    const [searchToDate,setSearchToDate] = useState("");
    const [queryParams,setQueryParams] = useState([]);




    const handleChange=(event)=>{
        event.preventDefault();
        switch(event.target.name){
            case "carrier":
                setLoading(true);
                setServices([]);
                setSearchCarrier(event.target.value);
                getServicesByCarrier(event.target.value)
                .then((data)=>{
                    setLoading(false);
                    setServices(data.data);
                })
                break;
            case "service":
                setSearchService(event.target.value);
                break;
            case "tracking_id":
                setSearchTrackingID(event.target.value);
                break;
            case "carrier_tracking_id":
                setSearchCarrierTrackingID(event.target.value);
                break;
            case "reciever_lastname":
                setSearchRecieverLastName(event.target.value);
                break;
            case "reciever_country":
                setSearchRecieverCountry(event.target.value);
                break;
            case "reciever_zipcode":
                setSearchRecieverZipcode(event.target.value);
                break;
            case "from_date":
                setSearchFromDate(event.target.value);
                break;
            case "to_date":
                setSearchToDate(event.target.value);
                break;
            case "reference":
                setSearchReference(event.target.value);
                break;
                
        }

    }





    



    



    function handleReset(){
        window.location.reload();
    }

    
    function handleFilter(){

        setLoading(true);
        setShipments([]);
        setShipmentsData([]);
        let data = {
            carrier:searchCarrier,
            service:searchService,
            reciever_lastname:searchRecieverLastName,
            reciever_country:searchRecieverCountry,
            reciever_zipcode:searchRecieverZipcode,
            from_date:searchFromDate,
            to_date:searchToDate,
            id:searchTrackingID,
            carrier_ref_id:searchCarrierTrackingID,
            reference:searchReference
        }
        setQueryParams(data);
        console.log(queryParams)
        getShippings(1,data)
        .then(data=>{
            
            setShipments(data.data.data);
            setShipmentsData(data.data);
            setLoading(false);
        })
    }
    const navigate = useNavigate();

    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        setShipments([]);
        setShipmentsData([]);

        setpage(value);
        
        getShippings(value,queryParams)
        .then(data=>{
            console.log(data.data);
            setShipments(data.data.data);
            setShipmentsData(data.data);
            setLoading(false);
        })
        
    }
    useEffect(()=>{
        setLoading(true);

        getCarriers()
        .then((data)=>{
            setCarriers(data.data);
        })
 
        getCountries()
        .then((data)=>{
            setCountries(data.data);
        })
        getShippings(page,queryParams)
        .then(data=>{
         
            setShipments(data.data.data);
            setShipmentsData(data.data);
            setLoading(false);
        })
    },[])

   
    return (
        <DashboardContent title="Shipments">


            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                      <Typography
                                        component="h2"
                                        variant="h6"
                                        color="inherit"
                                       
                                        noWrap
                                        sx={{ flexGrow: 1 }}
                                    >
                                        Filter
                                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                        <Grid item xs={12} sm={12} md={12}>
                           
                                
                                  

                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="carrier">Carrier</InputLabel>
                                                <Select
                                                    id="carrier"
                                                    name="carrier"
                                                    label="Carrier"
                                                    value={searchCarrier}
                                                    onChange={handleChange}

                                                >
                                                  {
                                                    carriers.map((item)=>{
                                                        return(
                                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                                        )
                                                    })
                                                  }
                                                    
                                                  
                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />


                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="service">Service</InputLabel>
                                                <Select
                                                    id="service"
                                                    name="service"
                                                    label="Service"
                                                    value={searchService}
                                                    onChange={handleChange}


                                                >
                                                  {
                                                    services.map((item)=>{
                                                        return(
                                                            <MenuItem value={item.id}>{item.name}</MenuItem>

                                                        );
                                                    })
                                                  }
                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="Shippingneo Tracking ID" value={searchTrackingID}  name="tracking_id" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="Carrier Tracking ID" value={searchCarrierTrackingID}  name="carrier_tracking_id" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                    </Grid>
                                    <Box mb={2} />

                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="Reciever: Last Name"  value={searchRecieverLastName} name="reciever_lastname" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>
                                                <InputLabel noWrap id="country_reciever">Reciever Country</InputLabel>
                                                <Select
                                                    onChange={handleChange}
                                                    id="country"
                                                    name="reciever_country"
                                                    label="Reciever Country"
                                                    value={searchRecieverCountry}

                                                >
                                                    {
                                                        countries.map((item)=>{
                                                            return (
                                                                <MenuItem key={item.id} value={item.iso}>{item.label}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />


                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="Reciever: ZIP code" value={searchRecieverZipcode} name="reciever_zipcode" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel noWrap id="source">Source</InputLabel>
                                                <Select
                                                    id="source"
                                                    onChange={handleChange}
                                                    label="Source"

                                                >
                                                     {
                                                        countries.map((item)=>{
                                                            return (
                                                                <MenuItem key={item.id} value={item.iso}>{item.label}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                    </Grid>
                                    <Box mb={2} />
                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="Sender: Last Name" name="sender_lastname" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>
                                                <InputLabel noWrap id="country_reciever">Sender Country</InputLabel>
                                                <Select
                                                    onChange={handleChange}
                                                    id="sender country"
                                                    name="sender_country"
                                                    label="sender country"

                                                >
                                                 {
                                                        countries.map((item)=>{
                                                            return (
                                                                <MenuItem key={item.id} value={item.iso}>{item.label}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />


                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="Sender: ZIP code" name="sender_zipcode" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="From Date"  value={searchFromDate}  name="from_date" variant="outlined" type="date" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange} label="To Date" value={searchToDate}  name="to_date"  variant="outlined" type="date" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                    </Grid>
                                    <Box mb={2} />

                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>

                                                <TextField onChange={handleChange}  label="Reference"  value={searchReference} name="reference" variant="outlined" InputLabelProps={{ shrink: true }} />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <FormControl fullWidth>
                                                <InputLabel noWrap id="country_reciever">Current Status</InputLabel>
                                                <Select
                                                    id="status"
                                                    onChange={handleChange} 
                                                    name="status"                                                    
                                                    label="Current Status"

                                                >
                                                    <MenuItem value={"IS"}>IS</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />


                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <FormControl fullWidth>
                                                <InputLabel noWrap id="country_reciever">Other</InputLabel>
                                                <Select
                                                onChange={handleChange} 
                                                    id="country"
                                                    label="Other"



                                                >
                                                   

                                                </Select>
                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>


                                    </Grid>

                                    

                                    <Box mt={2}></Box>








                                   
                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                        <Grid item xs={12} sm={12} md={12}>

                                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }} justifyContent="flex-end">


                                                <Grid item>
                                                    <Button variant="outlined" onClick={handleReset}>Reset</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" onClick={handleFilter}>Filter</Button>
                                                </Grid>





                                            </Grid>
                                        </Grid>

                                    </Grid>



                             
                         
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Paper elevation={2}  >
                <Box p={2} mt={5}>
                    <ShipmentTable  shipments={shipments} shipmentsData={shipmentsData} handleChangePage={handleChangePage}/>
                </Box>
            </Paper>

            <Fab color="secondary" aria-label="edit"  onClick={()=>navigate('/shipping/new-shipping')} 
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(5)
            }}>
                <AddIcon />
            </Fab>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </DashboardContent>
    );
}

export default Shipment;