import { Backdrop, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {React,useEffect,useState} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';

import { useParams } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { updateUserField, getUserField } from '../../apis/Userfield';
import { getCountries } from '../../apis/Country';

const AccountFields = () => {


    const [loading,setLoading] = useState(true);
    const [errors,setErrors] = useState("");
    const [company,set_company]=useState("");
    const [street_address,set_street_address]=useState("");
    const [street_number,set_street_number]=useState("");
    const [country,set_country] = useState("");
    const [state,set_state] = useState("");
    const [city,set_city] = useState("");
    const [countries,setCountries] = useState([]);

    const [zipcode,set_zipcode] = useState("");
    const [mobile_number,set_mobile_number] = useState("");
  


    const params = useParams();
    
    useEffect(()=>{
    
        getCountries()
        .then((data)=>{
            setCountries(data.data);
        })
     

        getUserField()
        .then((data)=>{
        
           setLoading(false);
            set_company(data.data.company);
            set_street_address(data.data.street_address);
            set_street_number(data.data.street_number)
            set_country(data.data.country)
            set_state(data.data.state)
            set_zipcode(data.data.zipcode)
            set_mobile_number(data.data.mobile_number)





            setLoading(false)





        })

    },[]);

    

    



   
    // const [user_id,set_user_id] = useState('');


    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){

            case "company":
                set_company(event.target.value);
                break;
            case "street_address":
                set_street_address(event.target.value);
                break;
            case "street_number":
                set_street_number(event.target.value);
                break;
            case "zipcode":
                set_zipcode(event.target.value);
                break;
            case "mobile_number":
                set_mobile_number(event.target.value);
                break;
            case "country":
                set_country(event.target.value);
                break;
            case "state":
                set_state(event.target.value);
                break;
            // case "user_id":
            //     set_user_id(event.target.value);
            //     break;
            
            default: break;
        }
    }
    
    
    const handleSubmit = (event)=>{
        
        setLoading(true);
      
        let data_api = {
            company:company,
            street_address:street_address,
            street_number:street_number,
            zipcode:zipcode,
            mobile_number:mobile_number,
            country:country,
            state:state,



            
        }
        updateUserField(data_api)
        .then((data)=>{
            setLoading(false);
        
        if(data.success){
      
            toast.success(data.message);
        }else{
        
           setErrors(data.data);
           

        }
    })

        
    }


    return (
        <DashboardContent title="Shipping">


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                               Setting
                            </Typography>

                       

                         

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                                    <FormControl fullWidth>

                                        <TextField label="Company" variant="outlined" InputLabelProps={{ shrink: true }}  name="company"  onChange={handleChange} value={company} 
                                        error={errors.company!=undefined?true:false}
                                        helperText={errors.company!==undefined?errors.company:''}
                                        />
                                    </FormControl>

                                    
                                    

                                </Grid>
                          
                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={10}>
                                    <FormControl fullWidth >

                                        <TextField label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street_address" value={street_address} onChange={handleChange} 
                                            error={errors.street_address!=undefined?true:false}
                                            helperText={errors.street_address!==undefined?errors.street_address:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth >

                                        <TextField label="Number" type="number" variant="outlined" InputLabelProps={{ shrink: true }}  name="street_number" value={street_number} onChange={handleChange}
                                          error={errors.street_number!=undefined?true:false}
                                          helperText={errors.street_number!==undefined?errors.street_number:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth >

                                        <TextField label="Zip Code" variant="outlined" InputLabelProps={{ shrink: true }} name="zipcode" value={zipcode} onChange={handleChange}
                                          error={errors.zipcode!=undefined?true:false}
                                          helperText={errors.zipcode!==undefined?errors.zipcode:''}
                                        
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                           
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth >

                                        <TextField label="State" variant="outlined" InputLabelProps={{ shrink: true }}  name="state" value={state} onChange={handleChange} 
                                            error={errors.state!=undefined?true:false}
                                            helperText={errors.state!==undefined?errors.state:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="country">Country</InputLabel>
                                        <Select
                                            id="carrier"
                                            name="country"
                                            value={country}
                                            onChange={handleChange}
                                            label="Country"
                                            required
                                            error={errors.country!=undefined?true:false}
                                            helperText={errors.country!==undefined?errors.country:''}
                                 
                                        >
                                           {
                                            countries.map((item)=>{
                                                return(
                                                    <MenuItem key={item.id} value={item.iso}>{item.label}</MenuItem>

                                                )
                                            })
                                           }
                                        </Select>
                                        <FormHelperText error={true} >{errors.country!==undefined?errors.country:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>

                                </Grid>

                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField label="Phone" type="number" variant="outlined" InputLabelProps={{ shrink: true }} name="mobile_number" value={mobile_number} onChange={handleChange} 
                                        
                                        error={errors.mobile_number!=undefined?true:false}
                                        helperText={errors.mobile_number!==undefined?errors.mobile_number:''}
                             
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>

                                </Grid>

                            </Grid>


                          

                            <Box mt={3}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={12}>

                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }} justifyContent="flex-end">

                                        <Grid item>
                                            
                                        <Button variant="outlined"   onClick={handleSubmit} >Save</Button>
                                        </Grid>
                                  





                                    </Grid>
                                </Grid>

                            </Grid>



                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </DashboardContent>
    );
}

export default AccountFields;