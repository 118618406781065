import { Button,  Paper } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import DashboardContent from '../../layouts/DashboardLayout';

const Pages=(props)=>{
    return (
        <DashboardContent title="All Pages">
            <Paper elevation={2} >
                <Box p={2}>
                    <Button component={Link} to="/api/generate" variant="contained">Regenrate Api Keys</Button>
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/account" variant="contained">Your Account</Button>
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/carriers" variant="contained">Carriers</Button>
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/shipping/new-shipping" variant="contained">NewShippping</Button>
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/shipment" variant="contained">Shipment</Button>
                </Box>

                
                <Box p={2}>
                    Auth
                    
                </Box>
                
                <Box p={2}>
                    <Button  component={Link} to="/login" variant="contained">Login</Button>
                    
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/register" variant="contained">Register</Button>
                    
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/forget" variant="contained">Forget</Button>
                    
                </Box>
                <Box p={2}>
                    <Button  component={Link} to="/reset" variant="contained">Reset</Button>
                    
                </Box>
            </Paper>
        </DashboardContent>
    );
}

export default Pages;