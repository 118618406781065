import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SettingsIcon from '@mui/icons-material/Settings';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import { Link } from 'react-router-dom';


export const mainListItems = (
  <React.Fragment>
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton  to="/shipping/new-shipping" component={Link}>
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Create Shipment" />
    </ListItemButton>
    <ListItemButton to="/shipment" component={Link}>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Shipments" />
    </ListItemButton>
    <ListItemButton component={Link} to="/carriers">
      <ListItemIcon>
        <ChildFriendlyIcon />
      </ListItemIcon>
      <ListItemText primary="Carrier" />
    </ListItemButton>
    {/* <ListItemButton>
      <ListItemIcon>
        <DirectionsRunIcon />
      </ListItemIcon>
      <ListItemText primary="Pickup" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LocalAtmIcon />
      </ListItemIcon>
      <ListItemText primary="Shipment Quote" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <KeyboardReturnIcon />
      </ListItemIcon>
      <ListItemText primary="Return Portal" />
    </ListItemButton> */}
    <ListItemButton component={Link} to="/api/generate">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Configurations" />
    </ListItemButton>
    <ListItemButton component={Link} to="/pages">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Pages" />
    </ListItemButton>
  </React.Fragment>
);
