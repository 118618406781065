import {base_url} from './baseurl';

export function getCarriers(){

    
     return fetch(base_url+'carriers',{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
       
    })
    .then(res=>res.json())
   
}

export function getCarriersForUser(){

    
    return fetch(base_url+'carrier_configurations/getByUser',{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
      
   })
   .then(res=>res.json())
  
}

export function saveCarrier(data){

    
    return fetch(base_url+'carrier_configuration',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function updateCarrier(data,id){

    
    return fetch(base_url+'carrier_configuration/'+id,{
       method:'PUT',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function deleteCarrier(id){

    
    return fetch(base_url+'carrier_configuration/'+id,{
       method:'DELETE',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },

      
   })
   .then(res=>res.json())
  
}

export function getCarrierById(id){

    
    return fetch(base_url+'carrier_configuration/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },

      
   })
   .then(res=>res.json())
  
}