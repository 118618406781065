import {base_url} from './baseurl';

export function getCountries(){
    return fetch(base_url+'countries',{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
       
    })
    .then(res=>res.json())
}