import { Backdrop, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {React,useEffect,useState} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getCarriers, saveCarrier } from '../../apis/Carriers';
import { useNavigate } from 'react-router-dom';

import {  toast } from 'react-toastify';

const NewCarrier = () => {
    const navigate = useNavigate();


    const [loading,setLoading] = useState(false);
    const [carriers,setCarriers]=useState([]);
    

    const [errors,setErrors] = useState([]);
    useEffect(()=>{
        getCarriers()
        .then((data)=>{
            setCarriers(data.data);
        })

        

    },[]);




    const [key_id,set_key_id] = useState('');
    const [key_secret,set_key_secret] = useState('');
    const [carrier_id,set_carrier_id] = useState('');
    const [account_number,set_account_number] = useState('');
    const [password,set_password] = useState('');
    const [username,set_username] = useState('');
    const [test_mode,set_test_mode] = useState(true);






    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "carrier_id":
                set_carrier_id(event.target.value);
                break;
            case "account_number":
                set_account_number(event.target.value);
                break;
            case "password":
                set_password(event.target.value);
                break;
            case "username":
                set_username(event.target.value);
                break;
            case "test_mode":
                    set_test_mode(!test_mode);                
                break;
           
            default: break;
        }
    }
    
    
    const handleSubmit = (event)=>{
        
        setLoading(true);
      
       let data = {
            carrier_id:carrier_id,
            account_number:account_number,
            password:password,
            username:username,
            test_mode:test_mode 
        }

        saveCarrier(data)
        .then((data)=>{
            
        setLoading(false);
        if(data.success){
            toast.success(data.message);
            navigate("/carriers/edit/"+data.data.id)
         
        }else{
            
            if(data.data!=undefined){
           setErrors(data.data);
            }else{
                toast.error(data.message);
            }

        }
    })

        
    }

    return (
        <DashboardContent title="Carriers">


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                New Carriers
                            </Typography>

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="carrier">Carrier</InputLabel>
                                        <Select
                                            id="carrier"
                                            name="carrier_id"
                                            label="Carrier"
                                            value={carrier_id}
                                            onChange={handleChange}
                                            error={errors.carrier_id!=undefined?true:false}
                                            helperText={errors.carrier_id!==undefined?errors.carrier_id:''}

                                        >
                                            {
                                                carriers.map(item=>(
                                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

                                                ))
                                            }
      
                                        </Select>
                                        <FormHelperText  error={true}>{errors.carrier_id!==undefined?errors.carrier_id:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />


                                </Grid>
                               
                            </Grid>

                           

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                             
                                    <FormControl fullWidth>

                                    <TextField  label="Account Number" variant="outlined" InputLabelProps={{ shrink: true }} name="account_number" onChange={handleChange} value={account_number} 
                                        error={errors.account_number!=undefined?true:false}
                                        helperText={errors.account_number!==undefined?errors.account_number:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                    <TextField  required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="username" onChange={handleChange} value={username} 
                                        error={errors.username!=undefined?true:false}
                                        helperText={errors.username!==undefined?errors.username:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                    <TextField required  type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="password" onChange={handleChange} value={password} 
                                        error={errors.password!=undefined?true:false}
                                        helperText={errors.password!==undefined?errors.password:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                    <FormControlLabel control={<Checkbox name="test_mode"  checked={test_mode}  onClick={handleChange} onChange={handleChange}  />} label="Test Mode" />

                                        </FormControl>
                                        <Box mb={2} />


                                </Grid>
                             
                                </Grid>
                           
                            
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                <FormControl >
                                            <Button variant="outlined"   onClick={handleSubmit} >Save Carrier</Button>
                                </FormControl>
                                </Grid>
                            </Grid>





                                   

                    

                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </DashboardContent>
    );
}

export default NewCarrier;