import { Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DashboardContent from '../../layouts/DashboardLayout';

const ApiKeyGenerator=(props)=>{
    return (
        <DashboardContent title="Api Key Generator">
            <Paper elevation={2} >
                <Box p={2}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    mb={4}
                    noWrap
                    sx={{ flexGrow: 1 }}
                    >
                        API Key
                    </Typography>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={12} sm={12} md={12}>
                    <FormControl>
  
                    <TextField id="outlined-basic" label="Sandbox Key" variant="outlined" InputLabelProps={{ shrink: true }} disabled  value="92871831675994734903"/>
                    
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    
                    <FormControl >
  
                    <TextField id="outlined-basic" label="Api Key" variant="outlined" InputLabelProps={{ shrink: true }} disabled  value="92871831675994734903"/>
                    
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    
                    <FormControl >
                    <Button variant="contained">Regenrate Api Keys</Button>
                    </FormControl>
                    </Grid>
                    </Grid>
                </Box>
            </Paper>
        </DashboardContent>
    );
}

export default ApiKeyGenerator;