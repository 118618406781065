import { Backdrop, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {React,useEffect,useState} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import {login} from '../../apis/Auth';
import {updateShipping, getShipping,bookShipping} from '../../apis/ShippingApi';
import { getServices, getServicesByCarrier } from '../../apis/Services';
import { getCarriers } from '../../apis/Carriers';
import { useParams,useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { getCountries } from '../../apis/Country';
import { getUserField } from '../../apis/Userfield';

const EditShipping = () => {
    const navigate = useNavigate();
    // const [loading,setLoading] = useState(false);
    const [services,setServices]=useState([]);
    const [carriers,setCarriers]=useState([]);
    const [shippingData,setShippingData]=useState([]);
    
    const [errors,setErrors] = useState([]);
    const [countries,setCountries] = useState([]);

    const [id,set_Id] = useState(0);

    const [reciever_company,set_reciever_company] = useState("");
    const [reciever_care_of,set_reciever_care_of] = useState("");
    const [reciever_first_name,set_reciever_first_name] = useState("");
    const [reciever_last_name,set_reciever_last_name] = useState("");
    const [reciever_street,set_reciever_street] = useState("");
    const [reciever_number,set_reciever_number] = useState("");
    const [reciever_zip_code,set_reciever_zip_code] = useState("");
    const [reciever_city,set_reciever_city] = useState("");
    const [reciever_state,set_reciever_state] = useState("");
    const [reciever_country,set_reciever_country] = useState("");
    const [reciever_phone,set_reciever_phone] = useState("");
    const [packaging_length,set_packaging_length] = useState("");
    const [packaging_width,set_packaging_width] = useState("");
    const [packaging_height,set_packaging_height] = useState("");
    const [packaging_weight,set_packaging_weight] = useState("");
    const [packaging_type,set_packaging_type] = useState("");
    const [packaging_ref,set_packaging_ref] = useState("");
    const [packaging_notification_email,set_packaging_notification_email] = useState("");
    const [description_goods,set_description_goods] = useState("");
    const [carrier_id,set_carrier_id] = useState("");
    const [service_id,set_service_id] = useState("");
    const [loading,setLoading] = useState(true);

    const params = useParams();
    
    useEffect(()=>{
    
        
     

        getCountries()
        .then((data)=>{
            setCountries(data.data);
        })

        getCarriers()
        .then((data)=>{
            setCarriers(data.data);
        })
        getServices()
        .then((data)=>{
            setServices(data.data);
        })
        getShipping(params.id)
        .then((data)=>{
            setShippingData(data.data)
            set_Id(data.data.id);
            set_reciever_company(data.data.reciever_company);
            set_reciever_care_of(data.data.reciever_care_of);
            
            set_reciever_first_name(data.data.reciever_first_name);
            set_reciever_last_name(data.data.reciever_last_name);

            set_reciever_street(data.data.reciever_street);
            set_reciever_number(data.data.reciever_number);

            set_reciever_zip_code(data.data.reciever_zip_code);
            set_reciever_city(data.data.reciever_city);

            set_reciever_state(data.data.reciever_state);
            set_reciever_country(data.data.reciever_country);

            set_reciever_phone(data.data.reciever_phone);
            set_packaging_length(data.data.packaging_length);

            set_packaging_width(data.data.packaging_width);
            set_packaging_height(data.data.packaging_height);
            set_packaging_weight(data.data.packaging_weight);
            set_packaging_type(data.data.packaging_type);

            set_packaging_ref(data.data.packaging_ref);
            set_packaging_notification_email(data.data.packaging_notification_email);

            set_description_goods(data.data.description_goods);
            set_carrier_id(data.data.carrier_id);
            set_service_id(data.data.service_id);

            setLoading(false)





        })

    },[]);

    

    



   
    // const [user_id,set_user_id] = useState('');


    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "reciever_company":
                set_reciever_company(event.target.value);
                break;
            case "reciever_care_of":
                set_reciever_care_of(event.target.value);
                break;
            case "reciever_first_name":
                set_reciever_first_name(event.target.value);
                break;
            case "reciever_last_name":
                set_reciever_last_name(event.target.value);
                break;
            case "reciever_street":
                set_reciever_street(event.target.value);
                break;
            case "reciever_number":
                set_reciever_number(event.target.value);
                break;
            case "reciever_zip_code":
                set_reciever_zip_code(event.target.value);
                break;
            case "reciever_city":
                set_reciever_city(event.target.value);
                break;
            case "reciever_state":
                set_reciever_state(event.target.value);
                break;
            case "reciever_country":
                set_reciever_country(event.target.value);
                break;
            case "reciever_phone":
                set_reciever_phone(event.target.value);
                break;
            case "packaging_length":
                set_packaging_length(event.target.value);
                break;
            case "packaging_width":
                set_packaging_width(event.target.value);
                break;
            case "packaging_height":
                set_packaging_height(event.target.value);
                break;
            case "packaging_weight":
                set_packaging_weight(event.target.value);
                break;
            case "packaging_type":
                set_packaging_type(event.target.value);
                break;    
            case "packaging_ref":
                set_packaging_ref(event.target.value);
                break;
            case "packaging_notification_email":
                set_packaging_notification_email(event.target.value);
                break;
            case "description_goods":
                set_description_goods(event.target.value);
                break;
            case "carrier_id":
                setServices([]);
                setLoading(true);
                set_carrier_id(event.target.value);
                getServicesByCarrier(event.target.value)
                .then((data)=>{
                    setLoading(false);
                    setServices(data.data);
                })
                break;
            case "service_id":
                set_service_id(event.target.value);
                break;
            // case "user_id":
            //     set_user_id(event.target.value);
            //     break;
            
            default: break;
        }
    }
    
    
    const handleSubmit = (event)=>{
        
        setLoading(true);
      
        
        updateShipping(id,reciever_company,reciever_care_of,reciever_first_name,reciever_last_name,reciever_street,reciever_number,reciever_zip_code,reciever_city,reciever_state,reciever_country,reciever_phone,packaging_length,packaging_width,packaging_height,packaging_weight,packaging_type,packaging_ref,packaging_notification_email,carrier_id,service_id,1,description_goods)
        .then((data)=>{
            setLoading(false);
        
        if(data.success){
            setErrors([]);
            toast.success(data.message);
        }else{
        
           setErrors(data.data);
           

        }
    })

        
    }

    const handleBook = (event) =>{
        event.preventDefault();
        setLoading(true);
      
        getUserField()
        .then((data)=>{
            let sender_fields = data.data;
            if(sender_fields.country==null || sender_fields.mobile_number==null ||  sender_fields.state==null || sender_fields.street_address==null || sender_fields.street_number==null || sender_fields.zipcode==null ){
                
                toast.error("Please fill your contact information.");
                setLoading(false);
                return false;
            }
            bookShipping(id,reciever_company,reciever_care_of,reciever_first_name,reciever_last_name,reciever_street,reciever_number,reciever_zip_code,reciever_city,reciever_state,reciever_country,reciever_phone,packaging_length,packaging_width,packaging_height,packaging_weight,packaging_type,packaging_ref,packaging_notification_email,carrier_id,service_id,1,description_goods)
            .then((data)=>{
                setLoading(false);
               
            if(data.success){
                setErrors([]);
               toast.success(data.message);
            }else{
               
               if(data.message!="Validation Error."){
               toast.error(data.message[0]);
               }else{
                setErrors(data.data);
               }
    
            }
    
    
            })

        })
        
      
    }


    return (
        <DashboardContent title="Shipping">


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Edit Shipping
                            </Typography>

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="carrier">Carrier</InputLabel>
                                        <Select
                                            id="carrier"
                                            name="carrier_id"
                                            label="Carrier"
                                            value={carrier_id}
                                            onChange={handleChange}
                                            error={errors.carrier_id!=undefined?true:false}
                                            helperText={errors.carrier_id!==undefined?errors.carrier_id:''}

                                        >

                                            {
                                                carriers.map(item=>(
                                                    <MenuItem key={item.id}    value={item.id}>{item.name}</MenuItem>

                                                ))
                                            }
      
                                        </Select>
                                        <FormHelperText  error={true}>{errors.carrier_id!==undefined?errors.carrier_id:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />


                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="service">Service</InputLabel>
                                        <Select
                                            id="service"
                                            name = "service_id"
                                            label="Service"
                                            value={service_id}
                                            onChange={handleChange}
                                            error={errors.service_id!=undefined?true:false}
                                            helperText={errors.service_id!==undefined?errors.service_id:''}
                                        >

                                            {
                                                services.map(item=>(
                                                    <MenuItem  key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    
                                                ))
                                            }
                                      
                                        </Select>
                                        <FormHelperText  error={true}>{errors.service_id!==undefined?errors.service_id:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                            </Grid>

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                        component="h3"
                                        variant="p"
                                        color="inherit"
                                        mb={2}
                                        mt={2}
                                        noWrap
                                        sx={{ flexGrow: 1 }}
                                    >
                                        Reciever
                                    </Typography>

                                </Grid>
                            </Grid>

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                                    <FormControl fullWidth>

                                        <TextField label="Company" variant="outlined" InputLabelProps={{ shrink: true }}  name="reciever_company"  onChange={handleChange} value={reciever_company} 
                                        error={errors.reciever_company!=undefined?true:false}
                                        helperText={errors.reciever_company!==undefined?errors.reciever_company:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                        <TextField  required label="First Name" variant="outlined" InputLabelProps={{ shrink: true }} name="reciever_first_name" onChange={handleChange} value={reciever_first_name} 
                                               error={errors.reciever_first_name!=undefined?true:false}
                                               helperText={errors.reciever_first_name!==undefined?errors.reciever_first_name:''}
                                               />

                                    </FormControl>
                                    <Box mb={2} />


                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>

                                        <TextField label="Care of" variant="outlined" InputLabelProps={{ shrink: true }}  name="reciever_care_of" value={reciever_care_of} onChange={handleChange}
                                                    error={errors.reciever_care_of!=undefined?true:false}
                                                    helperText={errors.reciever_care_of!==undefined?errors.reciever_care_of:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                        <TextField required label="Last Name" variant="outlined" InputLabelProps={{ shrink: true }} name="reciever_last_name" value={reciever_last_name}  onChange={handleChange}
                                                error={errors.reciever_last_name!=undefined?true:false}
                                                helperText={errors.reciever_last_name!==undefined?errors.reciever_last_name:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />

                                </Grid>
                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={10}>
                                    <FormControl fullWidth >

                                        <TextField required label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="reciever_street" value={reciever_street} onChange={handleChange} 
                                            error={errors.reciever_street!=undefined?true:false}
                                            helperText={errors.reciever_street!==undefined?errors.reciever_street:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth >

                                        <TextField required label="Number" type="number" variant="outlined" InputLabelProps={{ shrink: true }}  name="reciever_number" value={reciever_number} onChange={handleChange}
                                          error={errors.reciever_number!=undefined?true:false}
                                          helperText={errors.reciever_number!==undefined?errors.reciever_number:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={3}>
                                    <FormControl fullWidth >

                                        <TextField  required label="Zip Code" variant="outlined" InputLabelProps={{ shrink: true }} name="reciever_zip_code" value={reciever_zip_code} onChange={handleChange}
                                          error={errors.reciever_zip_code!=undefined?true:false}
                                          helperText={errors.reciever_zip_code!==undefined?errors.reciever_zip_code:''}
                                        
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth >

                                        <TextField required label="City" variant="outlined" InputLabelProps={{ shrink: true }} required  name="reciever_city" value={reciever_city} onChange={handleChange}
                                         error={errors.reciever_city!=undefined?true:false}
                                         helperText={errors.reciever_city!==undefined?errors.reciever_city:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <FormControl fullWidth >

                                        <TextField required label="State" variant="outlined" InputLabelProps={{ shrink: true }}  name="reciever_state" value={reciever_state} onChange={handleChange} 
                                            error={errors.reciever_state!=undefined?true:false}
                                            helperText={errors.reciever_state!==undefined?errors.reciever_state:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                               
                            <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="country">Country</InputLabel>
                                        <Select
                                            id="county"
                                            name="reciever_country"
                                            value={reciever_country}
                                            onChange={handleChange}
                                            label="Country"
                                            
                                            required
                                            error={errors.reciever_country!=undefined?true:false}
                                            helperText={errors.reciever_country!==undefined?errors.reciever_country:''}
                                 
                                        >
                                           {
                                            countries.map((item)=>{
                                                return(
                                                    <MenuItem  key={item.id} value={item.iso}>{item.label}</MenuItem>
                                                )
                                            })
                                           }
                                         
                                            
                                        </Select>
                                        <FormHelperText error={true} >{errors.reciever_country!==undefined?errors.reciever_country:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>

                                </Grid>

                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField required label="Phone" type="number" variant="outlined" InputLabelProps={{ shrink: true }} name="reciever_phone" value={reciever_phone} onChange={handleChange} 
                                        
                                        error={errors.reciever_phone!=undefined?true:false}
                                        helperText={errors.reciever_phone!==undefined?errors.reciever_phone:''}
                             
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>

                                </Grid>

                            </Grid>


                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                        component="h3"
                                        variant="p"
                                        color="inherit"
                                        mb={2}
                                        mt={2}
                                        noWrap
                                        sx={{ flexGrow: 1 }}
                                    >
                                        Packaging
                                    </Typography>

                                </Grid>

                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <TextField label="Length (in cm)" type="number" variant="outlined" InputLabelProps={{ shrink: true }}   name="packaging_length" value={packaging_length} onChange={handleChange}
                                          error={errors.packaging_length!=undefined?true:false}
                                          helperText={errors.packaging_length!==undefined?errors.packaging_length:''}
                               
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <TextField label="Width (in cm)" type="number" variant="outlined" InputLabelProps={{ shrink: true }}  name="packaging_width" value={packaging_width} onChange={handleChange}
                                         error={errors.packaging_width!=undefined?true:false}
                                         helperText={errors.packaging_width!==undefined?errors.packaging_width:''}
                              
                                        
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <TextField label="Height (in cm)" type="number" variant="outlined" InputLabelProps={{ shrink: true }}   name="packaging_height" value={packaging_height} onChange={handleChange}
                                               error={errors.packaging_height!=undefined?true:false}
                                               helperText={errors.packaging_height!==undefined?errors.packaging_height:''}
                                    
                                              
                                      
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <TextField label="Weight (in kg)" type="number" variant="outlined" InputLabelProps={{ shrink: true }} required  name="packaging_weight" value={packaging_weight} onChange={handleChange}
                                                error={errors.packaging_weight!=undefined?true:false}
                                                helperText={errors.packaging_weight!==undefined?errors.packaging_weight:''}
                                     
                                        
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel  id="package-type">Type</InputLabel>
                                        <Select
                                            id="carrier"
                                            name="packaging_type"
                                            value={packaging_type}
                                            onChange={handleChange}
                                            label="Carrier"
                                            error={errors.packaging_type!=undefined?true:false}
                                            helperText={errors.packaging_type!==undefined?errors.packaging_type:''}
                                 

                                        >
                                            <MenuItem value={"parcel"}>Parcel</MenuItem>
                                            <MenuItem value={"box"}>Box</MenuItem>
                                        </Select>
                                        <FormHelperText error={true}>{errors.packaging_type!==undefined?errors.packaging_type:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>



                            </Grid>
                            <Box mt={2}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField label="Reference" variant="outlined" InputLabelProps={{ shrink: true }} name="packaging_ref" value={packaging_ref} onChange={handleChange}
                                            error={errors.packaging_ref!=undefined?true:false}
                                            helperText={errors.packaging_ref!==undefined?errors.packaging_ref:''}
                                 
                                        />
                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="packaging_notification_email" value={packaging_notification_email} onChange={handleChange}
                                           error={errors.packaging_notification_email!=undefined?true:false}
                                           helperText={errors.packaging_notification_email!==undefined?errors.packaging_notification_email:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                </Grid>





                            </Grid>

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                        component="h3"
                                        variant="p"
                                        color="inherit"
                                        mb={2}
                                        mt={2}
                                        noWrap
                                        sx={{ flexGrow: 1 }}
                                    >
                                        Description of Goods
                                    </Typography>
                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField label="Description"
                                                    multiline
                                                    rows={2}
                                                    name="description_goods"
                                                    value={description_goods}
                                                    onChange={handleChange}
                                                    variant="outlined" InputLabelProps={{ shrink: true }} 
                                                    error={errors.description_goods!=undefined?true:false}
                                                    helperText={errors.description_goods!==undefined?errors.description_goods:''}
                                                    />

                                            </FormControl>
                                            <Box mb={2} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>

                                        </Grid>





                                    </Grid>
                                </Grid>

                            </Grid>

                            <Box mt={3}></Box>
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                <Grid item xs={12} sm={12} md={12}>

                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }} justifyContent="flex-end">

                                        <Grid item>

                                            <Button variant="outlined">Add Declaration</Button>
                                        </Grid>
                                        <Grid item>

                                            <Button variant="outlined">Calculate Price</Button>

                                        </Grid>
                                        <Grid item>
                                            
                                            <Button variant="outlined"   onClick={handleSubmit} >Save Shipment</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" onClick={handleBook}>Book Shipment</Button>
                                        </Grid>





                                    </Grid>
                                </Grid>

                            </Grid>



                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </DashboardContent>
    );
}

export default EditShipping;