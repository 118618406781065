import {base_url} from './baseurl';

export function createShipping(reciever_company,reciever_care_of="",reciever_first_name,reciever_last_name,reciever_street,reciever_number,reciever_zip_code,reciever_city,reciever_state,reciever_country,reciever_phone,packaging_length,packaging_width,packaging_height,packaging_weight,packaging_type,packaging_ref,packaging_notification_email,carrier_id,service_id,user_id,description_goods){
    const data = {
        "reciever_company":reciever_company,
        "reciever_care_of":reciever_care_of,
        "reciever_first_name":reciever_first_name,
        "reciever_last_name":reciever_last_name,
        "reciever_street":reciever_street,
        "reciever_number":reciever_number,
        "reciever_zip_code":reciever_zip_code,
        "reciever_city":reciever_city,
        "reciever_state":reciever_state,
        "reciever_country":reciever_country,
        "reciever_phone":reciever_phone,
        "packaging_length":packaging_length,
        "packaging_width":packaging_width,
        "packaging_height":packaging_height,
        "packaging_weight":packaging_weight,
        "packaging_type":packaging_type,
        "packaging_ref":packaging_ref,
        "packaging_notification_email":packaging_notification_email,
        "carrier_id":carrier_id,
        "user_id":user_id,
        "service_id":service_id,
        "description_goods":description_goods
    }

    
     return fetch(base_url+'shippings',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}

export function getShipping(id){
    
    
     return fetch(base_url+'shippings/'+id,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
    })
    .then(res=>res.json())
   
}

export function getShippings(page,data){
    
    
    return fetch(base_url+'shippings/?page='+page+'&'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function getLatestShipping(){
    // shipping/latest
    return fetch(base_url+'shipping/latest',{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
            
         //    "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(res=>res.json())
}

export function updateShipping(id,reciever_company,reciever_care_of="",reciever_first_name,reciever_last_name,reciever_street,reciever_number,reciever_zip_code,reciever_city,reciever_state,reciever_country,reciever_phone,packaging_length,packaging_width,packaging_height,packaging_weight,packaging_type,packaging_ref,packaging_notification_email,carrier_id,service_id,user_id,description_goods){
    const data = {
        "reciever_company":reciever_company,
        "reciever_care_of":reciever_care_of,
        "reciever_first_name":reciever_first_name,
        "reciever_last_name":reciever_last_name,
        "reciever_street":reciever_street,
        "reciever_number":reciever_number,
        "reciever_zip_code":reciever_zip_code,
        "reciever_city":reciever_city,
        "reciever_state":reciever_state,
        "reciever_country":reciever_country,
        "reciever_phone":reciever_phone,
        "packaging_length":packaging_length,
        "packaging_width":packaging_width,
        "packaging_height":packaging_height,
        "packaging_weight":packaging_weight,
        "packaging_type":packaging_type,
        "packaging_ref":packaging_ref,
        "packaging_notification_email":packaging_notification_email,
        "carrier_id":carrier_id,
        "user_id":user_id,
        "service_id":service_id,
        "description_goods":description_goods
    }

    
     return fetch(base_url+'shippings/'+id,{
        method:'PUT',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}


export function bookShipping(id,reciever_company,reciever_care_of="",reciever_first_name,reciever_last_name,reciever_street,reciever_number,reciever_zip_code,reciever_city,reciever_state,reciever_country,reciever_phone,packaging_length,packaging_width,packaging_height,packaging_weight,packaging_type,packaging_ref,packaging_notification_email,carrier_id,service_id,user_id,description_goods){
    const data = {

        "id":id,
        "reciever_company":reciever_company,
        "reciever_care_of":reciever_care_of,
        "reciever_first_name":reciever_first_name,
        "reciever_last_name":reciever_last_name,
        "reciever_street":reciever_street,
        "reciever_number":reciever_number,
        "reciever_zip_code":reciever_zip_code,
        "reciever_city":reciever_city,
        "reciever_state":reciever_state,
        "reciever_country":reciever_country,
        "reciever_phone":reciever_phone,
        "packaging_length":packaging_length,
        "packaging_width":packaging_width,
        "packaging_height":packaging_height,
        "packaging_weight":packaging_weight,
        "packaging_type":packaging_type,
        "packaging_ref":packaging_ref,
        "packaging_notification_email":packaging_notification_email,
        "carrier_id":carrier_id,
        "user_id":user_id,
        "service_id":service_id,
        "description_goods":description_goods
    }

    
     return fetch(base_url+'shipping/book',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}

export function deleteShippings(id){
    
    
    return fetch(base_url+'shippings/'+id,{
       method:'DELETE',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}