import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Dashboard from  './Pages/home/Dashboard';
import ApiKeyGenerator from './Pages/apis/ApiKeyGenerator';
import Account from './Pages/account/Account';
import NewShippping from './Pages/shipping/NewShipping';
import EditShipping from './Pages/shipping/EditShipping';
import Carriers from './Pages/carriers/Carriers';
import Shipment from './Pages/shipment/Shipment';
import Login from './Pages/auth/Login';
import Forget from './Pages/auth/Forget';
import ResetPassword from './Pages/auth/ResetPassword';
import Pages from './Pages/demo/Pages';
import Register from './Pages/auth/Register';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import NewCarrier from './Pages/carriers/NewCarriers';
import EditCarrier from './Pages/carriers/EditCarriers';
import AccountFields from './Pages/account/AccountFields';
import Verify from './Pages/auth/Verify';

function App() {
  return (
    <Router>
        <Routes>


         
        <Route path="/verify" element={<PublicRoute/>}>
            <Route  path="/verify" element={<Verify/>}/>
          </Route>


          <Route path="/login" element={<PublicRoute/>}>
            <Route  path="/login" element={<Login/>}/>
          </Route>

          <Route path="/register" element={<PublicRoute/>}>
            <Route  path="/register" element={<Register/>}/>  
          </Route>

          
          <Route path="/forget" element={<PublicRoute/>}>
            <Route  path="/forget" element={<Forget/>}/>      
          </Route>

          <Route path="/reset" element={<PublicRoute/>}>
            <Route  path="/reset" element={<ResetPassword/>}/>      
          </Route>


          <Route path="/" element={<PrivateRoute/>}>
            <Route  path="/" element={<Dashboard/>}/> 
          </Route>   
          
          <Route path="/api/generate" element={<PrivateRoute/>}>
            <Route  path="/api/generate" element={<ApiKeyGenerator/>}/>    
          </Route>   
          
          <Route path="/account" element={<PrivateRoute/>}>
            <Route  path="/account" element={<Account/>}/>    
          </Route>
          
          <Route path="/shipping/new-shipping" element={<PrivateRoute/>}>
            <Route  path="/shipping/new-shipping" element={<NewShippping/>}/>   
          </Route>
          
          <Route path="/shipping/edit-shipping/:id" element={<PrivateRoute/>}>
            <Route  path="/shipping/edit-shipping/:id" element={<EditShipping/>}/>
          </Route>   

          <Route path="/carriers" element={<PrivateRoute/>}>
            <Route  path="/carriers" element={<Carriers/>}/>    
          </Route>

          <Route path="/carriers/add" element={<PrivateRoute/>}>
            <Route  path="/carriers/add" element={<NewCarrier/>}/>    
          </Route>
          
          <Route path="/carriers/edit" element={<PrivateRoute/>}>
            <Route  path="/carriers/edit/:id" element={<EditCarrier/>}/>    
          </Route>


    
          <Route path="/settings" element={<PrivateRoute/>}>
            <Route  path="/settings" element={<AccountFields/>}/>    
          </Route>



          <Route path="/shipment" element={<PrivateRoute/>}>
            <Route  path="/shipment" element={<Shipment/>}/>    
          </Route>

          <Route path="/pages" element={<PrivateRoute/>}>
            <Route  path="/pages" element={<Pages/>}/>    
          </Route>


       
        </Routes>
   
    </Router>
  );
}

export default App;
