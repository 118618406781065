import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, CircularProgress, Grid, IconButton, Pagination, TableContainer, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { deleteShippings, getShippings } from '../../apis/ShippingApi';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { toast,ToastContainer } from 'react-toastify';
import DownloadIcon from '@mui/icons-material/Download';


export default function ShipmentTable(props) {

    const [loading,setLoading] = useState(false);

    
    const shipments = props.shipments;
    const shipmentsData = props.shipmentsData;
    const page = props.page;
    return (

        <React.Fragment >
            <ToastContainer/>
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Shipments ({shipmentsData.to ?? 0}/{shipmentsData.total})
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tracking No</TableCell>
                            <TableCell>Reference number</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Price of postage</TableCell>
                            <TableCell>Reciever</TableCell>
                            <TableCell>Carrier</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shipments.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.ref_id_from_carrier}</TableCell>
                                <TableCell>{ Moment(row.created_at).format('DD-MM-YYYY - hh:mm:a')}</TableCell>
                                <TableCell>{row.price_of_postage??"NA"}</TableCell>
                                <TableCell>{row.reciever_first_name}</TableCell>
                                <TableCell>{row.carrier.name}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>
                                    <IconButton  component={Link} to={"/shipping/edit-shipping/"+row.id}>
                                        <SearchIcon color="primary"/>
                                    </IconButton>
                                    <IconButton onClick={() => {navigator.clipboard.writeText(row.ref_id_from_carrier); }}>
                                        <ContentCopyIcon color="success" />
                                    </IconButton>
                                    { row.label_url?
                                    <IconButton onClick={()=>{
                                        window.open(row.label_url, '_blank');
                                    }}>
                                        <DownloadIcon/> 
                                    </IconButton>:''}
                                    <IconButton onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteShippings(row.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    window.location.reload();
                                                }else{
                                                    toast.error(data.data.message);
                                                }
                                            })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>
                             

                                </TableCell>



                            </TableRow>
                        ))}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {shipmentsData.last_page}
                  page={page}
                  onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}