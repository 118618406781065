import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Deposits from '../../components/Deposits';
import Orders from '../../components/Orders';
import { getLatestShipping } from '../../apis/ShippingApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Backdrop, Box, CircularProgress, IconButton, Pagination, TableContainer, Typography } from '@mui/material';
import { deleteShippings, getShippings } from '../../apis/ShippingApi';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast,ToastContainer } from 'react-toastify';
const Dashboard =(props)=>{
  
  const [shipments,setShipments] = useState([]);
  const [loading,setLoading]=useState(true)
  useEffect(()=>{
    getLatestShipping()
    .then(data=>{
      setLoading(false);
      setShipments(data.data)
    })
  },[])
    return (
        <DashboardContent title="Dashboard">
       <ToastContainer/>
            
        
            <Grid container spacing={3}>
          
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography
                  component="h2"
                  variant="h6"
                  color="inherit"
                  mb={4}
                  noWrap
                  sx={{ flexGrow: 1 }}
              >
                  Shipments 
              </Typography>
                <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Tracking No</TableCell>
                            <TableCell>Reference number</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Price of postage</TableCell>
                            <TableCell>Reciever</TableCell>
                            <TableCell>Carrier</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shipments.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.ref_id_from_carrier}</TableCell>
                                <TableCell>{ Moment(row.created_at).format('DD-MM-YYYY - hh:mm:a')}</TableCell>
                                <TableCell>{row.price_of_postage??"NA"}</TableCell>
                                <TableCell>{row.reciever_first_name}</TableCell>
                                <TableCell>{row.carrier.name}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>
                                    <IconButton  component={Link} to={"/shipping/edit-shipping/"+row.id}>
                                        <SearchIcon color="primary"/>
                                    </IconButton>
                                    <IconButton onClick={() => {navigator.clipboard.writeText(row.ref_id_from_carrier); }}>
                                        <ContentCopyIcon color="success" />
                                    </IconButton>
                                    <IconButton onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteShippings(row.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    window.location.reload();
                                                }else{
                                                    toast.error(data.data.message);
                                                }
                                            })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>

                                </TableCell>



                            </TableRow>
                        ))}
                      
                    </TableBody>
                    
                </Table>
                <Box mb={2} />

                <Link color="primary" to="/shipment"  component={Link} sx={{ mt: 3 }}>
                See more shipments
              </Link>
            </TableContainer>
                </Paper>
              </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </DashboardContent>
    );
}
export default Dashboard;