import {  Backdrop, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { getCarriersForUser } from '../../apis/Carriers';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const Carriers = (props) => {

    const [carriers,set_carriers] = useState([]);
    const [loading,setLoading] = useState(true);


    function createData(carrier, configuration_type, active) {
        return { carrier, configuration_type, active };
    }
    const navigate=  useNavigate();
    useEffect(()=>{
        getCarriersForUser()
        .then((data)=>{
            if(data.success){
            set_carriers(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

 
    return (
        <DashboardContent title="Your Carrier">
            <Paper elevation={2} >
                <Box p={2}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        mb={4}
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Individual Configurations
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell >Carriers</TableCell>
                                    <TableCell >Configuration Type</TableCell>
                                    <TableCell >Active</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {carriers.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >{row.carrier.name}</TableCell>
                                        <TableCell >USER</TableCell>
                                        <TableCell ><CheckCircleIcon color="success" /> </TableCell>
                                        <TableCell >
                                            <IconButton onClick={()=>{navigate('/carriers/edit/'+row.id)}} aria-label="delete"  color="primary">
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Fab color="secondary" aria-label="edit"  onClick={()=>{navigate('/carriers/add')}} 
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(5)
            }}>
                <AddIcon />
            </Fab>
        </DashboardContent>
    );
}

export default Carriers;