import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import {Link as RouteLink} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeDataCustom from '../../Theme/ThemeDataCustom';
import {  verifyOtp } from '../../apis/Auth';
import {  toast,ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PublicLayout from '../../layouts/PublicLayout';

const theme = createTheme(themeDataCustom());

export default function Verify() {

  const [loading,setLoading] = useState(false);
  

  const navigate = useNavigate();
   
  const handleSubmit = (event) => {
  
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    
   if(data.get('otp')==""){
      toast.error("OTP is required");
      setLoading(false);
      return false;
    }
 
    
    let data_api = {
      otp: data.get('otp'),
      email:localStorage.getItem('verify_username')
    };
    
   
   verifyOtp(data_api)
   .then((data)=>{
    setLoading(false);
    if(data.success){
      
       

        localStorage.clear();
        localStorage.setItem('token',"Bearer "+data.data.token);
        localStorage.setItem('user',data.data.user);
        localStorage.setItem('username',data.data.user.name);


        // return false;
     
        navigate("/");
        
    }else{
      toast.error("OTP is not valid!");
    }
    
  })

    


    

  };

  return (
    <PublicLayout>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
        </Backdrop>
      <ToastContainer/>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Verify
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
       
         
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="otp"
                  label="OTP"
                  type="number"
                  id="otp"

                />
              </Grid>
            
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Verify
            </Button>
            <Grid container justifyContent="flex-end">
            
              <Grid item xs={6} align="right">
                <Link  component={RouteLink}to="/login" variant="body2">
                  Go to Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      
        </PublicLayout> 
  );
}