import {base_url} from './baseurl';

export function getServices(){

    
     return fetch(base_url+'services',{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
       
    })
    .then(res=>res.json())
   
}


export function getServicesByCarrier(id){

    
    return fetch(base_url+'service/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
      
   })
   .then(res=>res.json())
  
}